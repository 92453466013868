<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">普通版</router-link>
      <router-link to="/signature">签名版</router-link>
      <router-link to="/paper">纸质版</router-link>
      <router-link to="/haikesi">海克斯版</router-link>
      <router-link to="/withJdg">JDG联名版</router-link>
      <router-link to="/withRng">RNG联名版</router-link>
      <router-link class="lastA" to="/info">更新记录</router-link>
    </div>
    <router-view />
    <div class="btm">
      <h5 style="color: gray">已生成
        <span>{{ papers}}</span>
        张秀兵证,帮助了
        <span>{{smfansNum}}</span>
        个人,其中图片来自网络,若有问题联系:plumliil@189.cn</h5>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.getSmfansData();
  },
  data() {
    return {
      smfansNum: 30,
      papers:42
    };
  },
  methods: {
    async getSmfansData() {
      const {data:res} = await axios.get("https://liiapi.eu.org/smfans");
      res.data.forEach(item => {
        this.papers+=item.type.length
      });
      this.smfansNum += res.total;
    },
  },
};
</script>

<style lang="less">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  // padding-top: 10px;

  a {
    position: relative;
    font-weight: bold;
    color: #2c3e50;
    font-size: 24px;
    text-decoration: none;
    margin: 0 15px;
    &.router-link-exact-active {
      color: #1a96a0;
    }
    &::after {
      position: absolute;
      content: "";
      height: 30px;
      width: 3px;
      right: -16px;
      top: 0;
      background-color: black;
    }
  }
  .lastA {
    &::after {
      position: absolute;
      content: "";
      height: 30px;
      width: 3px;
      right: -16px;
      top: 0;
      background-color: transparent;
    }
  }
}
.btm{
  // margin-bottom: 10px;
  position: absolute;
  // background-color: #1a96a0;
  bottom: -66px;
  left: 50%;
  line-height: 10px;
  transform: translateX(-50%);
  display: flex;
  span{
    font-size: 20px;
    color: #27bbad;
  }
}
button {
  cursor: pointer;
  z-index: 999;
}
div {
  position: relative;
}
</style>
