import Vue from 'vue'
import VueRouter from 'vue-router'
import Nomal from '../views/Nomal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'nomal',
    component: Nomal
  },
  {
    path: '/signature',
    name: 'Signature',
    component: () => import(/* webpackChunkName: "about" */ '../views/Signature.vue')
  },
  {
    path: '/paper',
    name: 'Paper',
    component: () => import(/* webpackChunkName: "about" */ '../views/Paper.vue')
  },
  {
    path: '/haikesi',
    name: 'HaiKeSi',
    component: () => import(/* webpackChunkName: "about" */ '../views/HaiKeSi.vue')
  },
  {
    path: '/withJdg',
    name: 'WithJDG',
    component: () => import(/* webpackChunkName: "about" */ '../views/WithJDG.vue')
  },
  {
    path: '/withRng',
    name: 'WithRNG',
    component: () => import(/* webpackChunkName: "about" */ '../views/WithRNG.vue')
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "about" */ '../views/Info.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
