<template>
  <div class="nomal">
    <input type="" v-model="userName" placeholder="在此输入昵称..." />
    <div class="btns">
      <button @click="createCanvas">生成预览图</button>
      <button @click="downloadCanvas">下载</button>
    </div>
    <div class="position">
      <button class="left" @click="toLeft">左</button>
      <button class="right" @click="toRight">右</button>
    </div>
    <div class="img">
      <div>
        <h1>原图</h1>
        <img alt="Vue logo" ref="signatureImg" src="../assets/nomal.png" />
      </div>
      <div>
        <h1>预览图</h1>
        <img v-if="isCreat" class="result" src="../assets/nomal.png" />
        <canvas
          v-if="!isCreat"
          ref="signatureCanvas"
          style="border: 1px solid #c3c3c3"
          width="450"
          height="650"
        >
          您的浏览器不支持 HTML5 canvas 标签。
        </canvas>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userName: "",
      isCreat: false,
      fileName: "",
      position: 255,
    };
  },
  created() {
    function judgeFileName(routeName) {
      switch (routeName) {
        case "Paper":
          return "纸质高清款";
        case "Signature":
          return "照片高糊款";
        case "WithJDG":
          return "JDG联名款";
        case "WithRNG":
          return "RNG联名款";
        case "HaiKeSi":
          return "海克斯限定款";
        default:
          return '普通版';
      }
    }
    this.fileName = judgeFileName(this.$route.name);
  },
  methods: {
    async setData(name,type){
      let data={
        name:name,
        type:type
      }
      const res=await axios.post('https://liiapi.eu.org/smfans/set',data);
      console.log(res);
    },
    downloadCanvas: function () {
      if (this.userName === "")
        return alert("请先输入昵称,并生成预览图进行确认🙂🙂🙂");
      const saveFile = function (data, fileName) {
        let save_link = document.createElement("a");
        save_link.href = data;
        save_link.download = fileName;

        let event = document.createEvent("MouseEvents");
        event.initEvent("click", true, false);
        save_link.dispatchEvent(event);
      };
      // 内部函数2(可忽略细节)
      const imgType = function (ty) {
        let type = ty.toLowerCase().replace(/jpg/i, "jpeg");
        var r = type.match(/png|jpeg|bmp|gif/)[0];
        return "image/" + r;
      };

      const type = "png"; //设置下载图片的格式
      const cans = this.$refs.signatureCanvas; //canvas实例子
      const img_png_src = cans.toDataURL("image/png"); //将canvas保存为图片
      const imgData = img_png_src.replace(imgType(type), "image/octet-stream");
      const filename = "秀兵证-" + this.fileName + "." + type; //下载图片的文件名
      this.setData(this.userName,this.fileName)
      // shoot
      saveFile(imgData, filename);
    },
    createCanvas() {
      // 创建canvas DOM元素，并设置其宽高和图片一样
      if (this.userName === "") return alert("请先输入昵称🙂🙂🙂");
      let image = this.$refs.signatureImg;
      let canvas = this.$refs.signatureCanvas;
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      let name = canvas.getContext("2d");
      let believer = canvas.getContext("2d");
      name.font = "40px Arial bolder";
      name.fillText(this.userName, 45, 375);
      believer.font = "20px Arial bolder";
      let len = this.userName.length;
      believer.fillText(this.userName, this.position - len * 4, 605);
      return canvas;
    },
    toRight() {
      this.position += 3;
      this.createCanvas();
    },
    toLeft() {
      this.position -= 3;
      this.createCanvas();
    },
  },
};
</script>

<style lang="less" scoped>
.nomal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    z-index: -1;
    div {
      margin: 0 50px;
      img {
        width: 450px;
        height: 650px;
      }
      .result {
        position: relative;
        &::after {
          position: absolute;
          content: "xxxxxxxxxx";
          font-size: 20px;
          color: red;
          z-index: 99999;
        }
      }
    }
  }
  input {
    width: 400px;
    height: 40px;
    outline: none;
    font-size: 20px;
    text-indent: 10px;
    margin-top: 20px;
  }
  .btns {
    button {
      cursor: pointer;
      width: 100px;
      height: 40px;
      margin-top: 20px;
      background: none;
      margin: 20px 20px;
      border-radius: 3px;
      font-size: 16px;
      padding: 5px;
    }
  }
  .position {
    position: absolute;
    width: 120px;
    top: 160px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    z-index: 999;
    &::before {
      content: "小签名位置微调";
      font-weight: 700;
      position: absolute;
      top: -30px;
    }
    button {
      width: 50px;
      height: 30px;
      background: none;
    }
  }
}
</style>
